import React from 'react'
import { DataGrid } from '@mui/x-data-grid'
import { Stack } from '@mui/system'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { BULLET } from '../Constants/Symbols'
import { PROJECTS } from '../Constants/Projects'
import { useStateContext } from '../ContextProvider'
import { useState } from 'react'

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

function formatDate(isoDate) {
  if (isoDate === 'Present') return 'Present'
  const date = new Date(isoDate)
  const month = date.getMonth()
  const year = date.getFullYear()
  return `${months[month]} ${year}`
}

const columns = [
  { field: 'title', headerName: 'Title', width: 378 },
  { field: 'organization', headerName: 'Organization', width: 150 },
  {
    field: 'startDate',
    headerName: 'Date',
    width: 150,
    renderCell: (params) => {
      return <div>{formatDate(params.row.startDate)}</div>
    },
  },
]

const ROW_HEIGHT = 52
const WIDTH = columns.map((column) => column.width).reduce((partialSum, a) => partialSum + a, 0) + 2
const HEIGHT = PROJECTS.length * ROW_HEIGHT + 58

const Projects = (props) => {
  const { selectedItem, setSelectedItem, isMobile } = useStateContext()
  const selectedIndex = PROJECTS.indexOf(selectedItem) + 1

  //for mobile
  const [expanded, setExpanded] = useState(false)

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  return (
    <div>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h4">Projects</Typography>
      <Divider />
      {isMobile ? (
        <div>
          {PROJECTS.map((project, idx) => (
            <Accordion
              key={idx}
              ref={props.projectRefs[idx]}
              expanded={expanded === `panel${idx}`}
              onChange={handleChange(`panel${idx}`)}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                <Typography variant="body1">{project.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body2" color="text.secondary">
                  {`${formatDate(project.startDate)} - ${formatDate(project.endDate)}`}
                </Typography>
                <Typography variant="body">
                  {`${project.organization} - ${project.position} - ${project.role}`}
                </Typography>
                {project.summary.map((bullet, idx) => (
                  <Typography key={idx} variant="body2">
                    {`${BULLET} ${bullet}`}
                  </Typography>
                ))}
                <Stack direction="row" spacing={2} marginTop={1}>
                  {project.technologies.map((icon) => {
                    const Icon = icon
                    return <Icon key={icon} />
                  })}
                </Stack>
                {project.additionalInfo === null ? (
                  <></>
                ) : (
                  <div>
                    <Divider sx={{ mt: 2, mb: 2 }} />
                    {project.additionalInfo}
                  </div>
                )}
              </AccordionDetails>
            </Accordion>
          ))}
        </div>
      ) : (
        <Stack direction="row">
          <div style={{ height: HEIGHT, minWidth: WIDTH }}>
            <DataGrid
              rows={PROJECTS}
              columns={columns}
              disableColumnMenu
              hideFooter
              sx={{
                '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
                  display: 'none',
                },
              }}
              onCellClick={(e) => {
                // if (selectedItem === e.row) setSelectedItem(null)
                // else setSelectedItem(e.row)
                setSelectedItem(e.row)
              }}
              selectionModel={selectedIndex}
            />
          </div>
          {selectedItem === null ? (
            <></>
          ) : (
            <Box style={{ overflowY: 'auto', maxHeight: HEIGHT, width: '100%', overflowX: 'auto' }}>
              <Card sx={{ width: '100%' }} elevation={0}>
                <CardContent>
                  <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                    Project Information
                  </Typography>
                  <Grid container spacing={4}>
                    <Grid item xs>
                      <Typography variant="h5">{selectedItem.title}</Typography>
                      <Typography sx={{ mb: 1.5 }} variant="h6" color="text.secondary">
                        {`${selectedItem.organization} - ${selectedItem.position} - ${selectedItem.role}`}
                      </Typography>
                    </Grid>
                    <Grid item xs>
                      <Stack direction="row" justifyContent="end">
                        <Typography marginBottom={2} variant="h5">
                          {`${formatDate(selectedItem.startDate)} - ${formatDate(selectedItem.endDate)}`}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                  {selectedItem.summary.map((bullet, idx) => (
                    <Typography key={idx}>{`${BULLET} ${bullet}`}</Typography>
                  ))}
                  <Divider sx={{ mt: 2, mb: 2 }} />
                  <Grid container wrap="wrap">
                    {selectedItem.technologies.map((icon, idx) => {
                      const Icon = icon
                      return (
                        <div style={{ marginRight: '10px' }} key={idx}>
                          <Icon key={icon} />
                        </div>
                      )
                    })}
                  </Grid>
                  {/* <Stack direction="row" spacing={2}>
                    {selectedItem.technologies.map((icon) => {
                      const Icon = icon
                      return <Icon key={icon} />
                    })}
                  </Stack> */}
                  {selectedItem.additionalInfo === null ? (
                    <></>
                  ) : (
                    <div>
                      <Divider sx={{ mt: 2, mb: 2 }} />
                      {selectedItem.additionalInfo}
                    </div>
                  )}
                </CardContent>
              </Card>
            </Box>
          )}
        </Stack>
      )}
    </div>
  )
}

export default Projects
