export const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

export const copyToClipboard = async (string) => {
  if ('cliboard' in navigator) {
    await navigator.clipboard.writeText(string)
  } else {
    document.execCommand('copy', true, string)
  }
}
