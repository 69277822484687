import CircleIcon from '@mui/icons-material/Circle'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import React from 'react'
import { Stack } from '@mui/system'

const Scale = (props) => {
  const numPoints = props.numPoints ? props.numPoints : 5
  const rating = props.rating ? props.rating : 3
  const color = props.color ? props.color : 'red'
  return (
    <Stack direction="row">
      {[...Array(rating)].map((_, idx) => (
        <CircleIcon style={{ color: color }} key={idx} />
      ))}
      {[...Array(numPoints - rating)].map((_, idx) => (
        <PanoramaFishEyeIcon style={{ color: color }} key={idx} />
      ))}
    </Stack>
  )
}

export default Scale
