import { createContext, useContext, useState } from 'react'
import { PROJECTS } from './Constants/Projects'
import { MOBILE_MAX_WIDTH } from './Constants/Values'

const StateContext = createContext()

export const ContextProvider = ({ children }) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= MOBILE_MAX_WIDTH)
  const [darkMode, setDarkMode] = useState(true)
  const [selectedItem, setSelectedItem] = useState(PROJECTS[0])

  return (
    <StateContext.Provider
      value={{
        isMobile,
        setIsMobile,
        darkMode,
        setDarkMode,
        selectedItem,
        setSelectedItem,
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export const useStateContext = () => useContext(StateContext)
