import { Button, Card, CardActions, CardContent, IconButton, Snackbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import { openInNewTab } from '../Utils/AuxFuncs'
import MapIcon from '@mui/icons-material/Map'
import CallIcon from '@mui/icons-material/Call'
import CloseIcon from '@mui/icons-material/Close'
import { Stack } from '@mui/system'
import { useState } from 'react'

const Contact = () => {
  const [open, setOpen] = useState(false)

  return (
    <Card sx={{ width: 300 }}>
      <CardContent>
        <Typography color="text.secondary" gutterBottom>
          Contact Information
        </Typography>

        <Typography variant="h5" component="div">
          Nicholas Tobler
        </Typography>
        <Typography color="text.secondary">nicholas.tobler@gmail.com</Typography>
        <Stack alignItems="center" spacing={2} direction="row">
          <Tooltip title="Copy Number" disableInteractive>
            <IconButton onClick={() => setOpen(true)}>
              <CallIcon />
            </IconButton>
          </Tooltip>
          <Typography>(240) 577-8858</Typography>
        </Stack>
        <Stack alignItems="center" spacing={2} direction="row">
          <Tooltip title="Open in Google Maps" disableInteractive>
            <IconButton onClick={() => openInNewTab('http://maps.google.com/?q=1205 Northglen Court, Mansfield, TX')}>
              <MapIcon />
            </IconButton>
          </Tooltip>
          <Typography variant="body2">
            1205 Northglen Court
            <br />
            Mansfield TX, 76063
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => openInNewTab('mailto:nicholas.tobler@gmail.com')}>
          <Typography color="link" sx={{ textDecoration: 'underline' }}>
            Send me an email
          </Typography>
        </Button>
      </CardActions>
      <Snackbar
        open={open}
        autoHideDuration={5000}
        onClose={() => setOpen(false)}
        message="Number copied to clipboard"
        action={
          <IconButton color="secondary" onClick={() => setOpen(false)}>
            <CloseIcon size="large" />
          </IconButton>
        }
      />
    </Card>
  )
}

export default Contact
