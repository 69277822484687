import { AppBar, Button, Grid, IconButton, SwipeableDrawer, Toolbar, Typography, useTheme } from '@mui/material'
import { Stack } from '@mui/system'
import LightModeIcon from '@mui/icons-material/LightMode'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import MenuIcon from '@mui/icons-material/Menu'
import React from 'react'
import { PAGES } from '../Constants/Pages'
import { useStateContext } from '../ContextProvider'
import { useState } from 'react'
import { NAVBAR_HEIGHT } from '../Constants/Values'

const Navbar = (props) => {
  const { darkMode, isMobile } = useStateContext()
  const theme = useTheme()
  const textColor = theme.palette.primary.contrastText
  const [openDrawer, setOpenDrawer] = useState(false)

  return (
    <AppBar ref={props.refer} position="fixed" color="primary">
      <Toolbar variant="dense">
        <Grid container>
          <Grid item xs>
            {isMobile ? (
              <IconButton size="large" onClick={() => setOpenDrawer(!openDrawer)}>
                <MenuIcon />
              </IconButton>
            ) : (
              <></>
            )}
          </Grid>
          {isMobile ? (
            <SwipeableDrawer
              anchor="left"
              open={openDrawer}
              onClose={() => setOpenDrawer(!openDrawer)}
              onOpen={() => setOpenDrawer(!openDrawer)}
              sx={{ zIndex: 0 }}
            >
              <div style={{ marginTop: `${NAVBAR_HEIGHT}px` }}></div>
              {PAGES.map((page, idx) => (
                <Button
                  key={page.name}
                  variant="text"
                  onClick={() => {
                    props.pageSelect(idx)
                    setOpenDrawer(!openDrawer)
                  }}
                  sx={{ justifyContent: 'flex-start' }}
                >
                  <Typography color={textColor} typography={theme.typography.h5}>
                    {page.name}
                  </Typography>
                </Button>
              ))}
            </SwipeableDrawer>
          ) : (
            <Grid item xs={10}>
              <Stack direction="row" justifyContent="center" spacing={10}>
                {PAGES.map((page, idx) => (
                  <Button key={page.name} variant="text" onClick={() => props.pageSelect(idx)}>
                    <Typography color={textColor} typography={theme.typography.h5}>
                      {page.name}
                    </Typography>
                  </Button>
                ))}
              </Stack>
            </Grid>
          )}
          <Grid item xs>
            <Stack direction="row" justifyContent="end">
              <IconButton size="large" onClick={props.clicked}>
                {darkMode ? <LightModeIcon></LightModeIcon> : <DarkModeIcon></DarkModeIcon>}
              </IconButton>
            </Stack>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default Navbar
