import { Card, CardContent, CardMedia, Dialog, DialogContent, Divider, Stack, Tooltip, Typography } from '@mui/material'
import { CERTIFICATIONS } from '../Constants/Certifications'
import React, { useState } from 'react'
import { useStateContext } from '../ContextProvider'

const Certifications = (props) => {
  const [selectedCertification, setSelectedCertification] = useState(null)
  const { isMobile } = useStateContext()
  return (
    <div>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h4">Certifications</Typography>
      <Divider />
      <Stack direction="row" sx={{ flexWrap: 'wrap' }} justifyContent="center">
        {CERTIFICATIONS.map((certification, idx) => (
          <Tooltip key={idx} title={certification.name}>
            <Card sx={{ width: 400, margin: '8px' }} onClick={() => setSelectedCertification(certification)}>
              <CardMedia component="img" image={certification.certificate} />
            </Card>
          </Tooltip>
        ))}
      </Stack>
      {!isMobile && selectedCertification && (
        <Dialog open={selectedCertification !== null} onClose={() => setSelectedCertification(null)} maxWidth="md">
          <DialogContent>
            <Card>
              <CardMedia component="img" image={selectedCertification.certificate} />
              <CardContent>
                <Typography variant="h6">{selectedCertification.name}</Typography>
                {/* You can add additional details here */}
              </CardContent>
            </Card>
          </DialogContent>
        </Dialog>
      )}
    </div>
  )
}

export default Certifications
