import About from '../Pages/About'
import Projects from '../Pages/Projects'
import Skills from '../Pages/Skills'
import Certifications from '../Pages/Certifications'
import More from '../Pages/More'

export const PAGES = [
  { name: 'About', component: About },
  { name: 'Projects', component: Projects },
  { name: 'Skills', component: Skills },
  { name: 'Cerfications', component: Certifications },
  { name: 'More', component: More },
]
