import { Box, Card, CardMedia, Divider, Grid, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import profile from './profile.jpg'
import umbc from './umbc.png'
import React from 'react'
import Contact from './Contact'
import { useStateContext } from '../ContextProvider'
import { BULLET } from '../Constants/Symbols'

const About = () => {
  const { isMobile } = useStateContext()
  return (
    <div>
      {isMobile ? (
        <div>
          <Typography variant="h5">Experience</Typography>
          <Divider />
          <Grid container>
            <Grid item xs>
              <Stack>
                <Typography variant="body">NAWCAD</Typography>
                <Typography variant="body2" color="text.secondary">
                  Software Engineer
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5} display="flex" justifyContent="end" marginRight={2}>
              <Typography variant="body2">Aug '19 - Present</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Stack>
                <Typography variant="body">NAWCAD</Typography>
                <Typography variant="body2" color="text.secondary">
                  Computer Science Intern
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5} display="flex" justifyContent="end" marginRight={2}>
              <Typography variant="body2">Jun '17 - Aug '19</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <Stack>
                <Typography variant="body">ASEC</Typography>
                <Typography variant="body2" color="text.secondary">
                  Computer Science Intern
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={5} display="flex" justifyContent="end" marginRight={2}>
              <Typography variant="body2">Jun '16 - Aug '17</Typography>
            </Grid>
          </Grid>
          <Typography variant="h5" marginTop={2}>
            Education
          </Typography>
          <Divider />
          <Grid container wrap="nowrap">
            <Grid item xs={8}>
              <Stack>
                <Typography variant="body">University of Maryland,</Typography>
                <Typography variant="body">Baltimore County</Typography>
                <Typography variant="body2">{BULLET} B.S. in Computer Science</Typography>
                <Typography variant="body2">{BULLET} Minor in Mathematics</Typography>
                <Typography variant="body2">{BULLET} Honors: summa cum laude</Typography>
              </Stack>
            </Grid>
            <Grid item xs display="flex" justifyContent="end">
              <Card sx={{ width: 82, height: 82 }}>
                <CardMedia component="img" image={umbc} />
              </Card>
            </Grid>
          </Grid>
          <Box marginTop={2}>
            <Contact />
          </Box>
        </div>
      ) : (
        <Grid container wrap="nowrap">
          <Grid item xs>
            <Typography variant="h5">Experience</Typography>
            <Divider />
            <Grid container>
              <Grid item xs>
                <Typography variant="body">NAWCAD - Software Engineer</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end" marginRight={2}>
                <Typography variant="body">August 2019 - Present</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Typography variant="body">NAWCAD - Computer Science Intern</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end" marginRight={2}>
                <Typography variant="body">June 2017 - August 2019</Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs>
                <Typography variant="body">ASEC - Computer Science Intern</Typography>
              </Grid>
              <Grid item xs={4} display="flex" justifyContent="end" marginRight={2}>
                <Typography variant="body">June 2016 - August 2017</Typography>
              </Grid>
            </Grid>
            <Typography variant="h5" marginTop={2}>
              Education
            </Typography>
            <Divider />
            <Grid container>
              <Grid item xs>
                <Stack>
                  <Typography variant="body">University of Maryland, Baltimore County</Typography>
                  <Typography variant="body" marginLeft={2}>
                    Bachelor of Science in Computer Science, Minor in Mathematics
                  </Typography>
                  <Typography variant="body" marginLeft={2}>
                    Honors: summa cum laude (GPA: 4.0/4.0)
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs="auto" display="flex" justifyContent="end">
                <Card sx={{ width: 82, height: 82 }}>
                  <CardMedia component="img" image={umbc} />
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs="auto" display="flex" justifyContent="end">
            <div>
              <Contact />
            </div>
            <Card sx={{ width: 200, height: 263 }}>
              <CardMedia component="img" image={profile} sx={{}} />
            </Card>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default About
