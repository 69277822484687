import { Box, Grid, Typography } from '@mui/material'
import {
  CIcon,
  CppIcon,
  CSharpIcon,
  MatlabIcon,
  PythonIcon,
  ArduinoIcon,
  QtCreatorIcon,
  JavaIcon,
  ReactJSIcon,
  DockerIcon,
  KubernetesIcon,
  NginxIcon,
  JavascriptIcon,
  GitIcon,
  NodeJSIcon,
  PostgreSQLIcon,
  RESTIcon,
  AWSIcon,
  AndroidIcon,
} from './TechnologyIcons/Icons'
import financialityPreview from './Screenshots/financialityPreview.png'

const projects = [
  {
    title: 'Acoustic Computation Desktop App Development',
    organization: 'NAWCAD',
    startDate: '2019-08-15T00:00:00+00:00',
    endDate: '2021-03-15T00:00:00+00:00',
    position: 'Software Engineer',
    role: 'Co-Developer',
    summary: [
      'Increased acoustic processing capacity by 3000% through the parallelization of network nodes',
      'Won first place out of 149 teams for demonstrating outstanding Rapid Prototyping Capabilities',
      'Communicated effectively with users to quickly implement over 20 feature requests',
    ],
    technologies: [CppIcon, QtCreatorIcon],
    additionalInfo: null,
  },
  {
    title: 'Acoustic Health Monitoring Innovation Challenge',
    organization: 'NAWCAD',
    startDate: '2021-03-15T00:00:00+00:00',
    endDate: '2021-11-15T00:00:00+00:00',
    position: 'Software Engineer',
    role: 'Team Lead',
    summary: [
      'Trained a machine learning classifier to predict faulty gearbox conditions with 98% accuracy',
      'Achieved 90% less latency over the existing MATLAB interface, using parallel processing in C',
      'Led biweekly team meetings and organized the final out brief presentation to the Admiral',
    ],
    technologies: [CIcon, MatlabIcon, PythonIcon],
    additionalInfo: null,
  },
  {
    title: 'P-8 Mission Communications',
    organization: 'NAWCAD',
    startDate: '2021-11-15T00:00:00+00:00',
    endDate: '2023-04-15T00:00:00+00:00',
    position: 'Software Engineer',
    role: 'Team Member',
    summary: [
      'Developed a C# network client to handle over 200 network communication patterns with the P-8',
      'Used Java to drastically decreased latency of interactive plots of real time data from the ocean',
    ],
    technologies: [CSharpIcon, JavaIcon, DockerIcon, KubernetesIcon, GitIcon],
    additionalInfo: null,
  },
  {
    title: 'NUAMP Sonobuoy Software Development',
    organization: 'Eagle Systems',
    startDate: '2023-05-15T00:00:00+00:00',
    endDate: 'Present',
    position: 'Software Engineer',
    role: 'Team Member',
    summary: [
      'Enhanced NUAMP sonobuoy displays for improved operator efficiency',
      'Incorporated multiple features into AOFP software to meet user-specific requests',
    ],
    technologies: [JavaIcon, JavascriptIcon, DockerIcon, KubernetesIcon, GitIcon],
    additionalInfo: null,
  },
  {
    title: 'Dynamic Load Balancing Library Development',
    organization: 'NAWCAD',
    startDate: '2017-06-15T00:00:00+00:00',
    endDate: '2019-08-15T00:00:00+00:00',
    position: 'Computer Science Intern',
    role: 'Team Member',
    summary: ["Used Microsoft's Message Passing Interface to distribute workloads across a 16-node network"],
    technologies: [CppIcon],
    additionalInfo: null,
  },
  {
    title: 'Acoustic Flight Data Processing',
    organization: 'NAWCAD',
    startDate: '2018-06-15T00:00:00+00:00',
    endDate: '2018-09-15T00:00:00+00:00',
    position: 'Computer Science Intern',
    role: 'Team Member',
    summary: ['Created scripts for data visualization, and trained 6 engineers to use them to generate reports'],
    technologies: [MatlabIcon],
    additionalInfo: null,
  },
  {
    title: 'Acoustic Ray Tracing Library Development',
    organization: 'ASEC',
    startDate: '2016-06-15T00:00:00+00:00',
    endDate: '2017-09-15T00:00:00+00:00',
    position: 'Computer Science Intern',
    role: 'Team Member',
    summary: ['Implemented the logic of an advanced mathematical paper to interpolate 3D sound speed vectors'],
    technologies: [CIcon],
    additionalInfo: null,
  },
  {
    title: 'Christmas Tree Light Show',
    organization: 'Self',
    startDate: '2021-12-15T00:00:00+00:00',
    endDate: 'Present',
    position: 'Software Engineer',
    role: 'Sole Developer',
    summary: [
      'Created C++ software to control Christmas tree lights that accommodated complex light patterns',
      'Wrote a python script to automatically map LED positions from a video to a 3D coordinate system',
    ],
    technologies: [CIcon, ArduinoIcon],
    additionalInfo: (
      <Grid container spacing={8} sx={{ overflowX: 'auto' }}>
        <Grid item xs="auto">
          <Typography variant="body2" color="text.secondary">
            [Redirect/Ad Blockers may affect video loading]
          </Typography>
          <iframe
            src="https://drive.google.com/file/d/1DOvswkXKZCHv3prhPG4O3TdwQ1ZMNISt/preview"
            width="400"
            height="480"
            allow="autoplay"
            title="Horizontal Strips"
          ></iframe>
          <Box maxWidth={400}>
            <Typography variant="body2" sx={{ mb: 3 }}>
              A video demonstrating how the lights can be controlled horizontally (not just along the strips) since they
              were mapped in 3D space.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs="auto">
          <iframe
            src="https://drive.google.com/file/d/1DMslali6gWPE9viVJDFVhWrItp7HhfOZ/preview"
            width="400"
            height="480"
            allow="autoplay"
            title="Spherical"
          ></iframe>
          <Box maxWidth={400}>
            <Typography variant="body2">
              A video demonstrating the spherical function, where the lights radiate inward (or outward depending on
              your interpretation) toward the origin.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ),
  },
  {
    title: 'Dorm Room Automation',
    organization: 'Self',
    startDate: '2018-05-15T00:00:00+00:00',
    endDate: '2018-12-15T00:00:00+00:00',
    position: 'Software/Hardware Engineer',
    role: 'Co-Developer',
    summary: ['Setup the Raspberry Pi to remotely control lights, display weather and show stock information'],
    technologies: [CIcon, PythonIcon],
    additionalInfo: null,
  },
  {
    title: 'Personal Website',
    organization: 'Self',
    startDate: '2022-11-15T00:00:00+00:00',
    endDate: 'Present',
    position: 'Full Stack Engineer',
    role: 'Sole Developer',
    summary: [
      'Used ReactJS to build a website for displaying information about my career',
      'Self hosted the website on my personal server, using Docker and NGINX as a reverse proxy',
      "(Look around, it's the page you are on)",
    ],
    technologies: [ReactJSIcon, JavascriptIcon, DockerIcon, NginxIcon],
    additionalInfo: null,
  },
  {
    title: 'Grocery App Meal Planning',
    organization: 'Self',
    startDate: '2023-03-15T00:00:00+00:00',
    endDate: 'Present',
    position: 'Full Stack Engineer',
    role: 'Sole Developer',
    summary: [
      'Creating a full stack cloud based application for meal planning and nutrition tracking',
      'Uses AWS cloud services to host both user data and recipe data',
      'Android front end application for accessing the api and easily managing meals [coming soon]',
    ],
    technologies: [AWSIcon, JavascriptIcon, PostgreSQLIcon, RESTIcon, AndroidIcon],
  },
  {
    title: 'Financiality (Finance Split Site)',
    organization: 'Self',
    startDate: '2022-07-15T00:00:00+00:00',
    endDate: 'Present',
    position: 'Full Stack Engineer',
    role: 'Sole Developer',
    summary: [
      'Creating a full stack application for managing finances and split purchases with a group',
      'Enables complex split arrangments, allows for permission control, option to add interest',
      '[Work in progress]',
    ],
    technologies: [ReactJSIcon, NodeJSIcon, JavascriptIcon, DockerIcon, PostgreSQLIcon, RESTIcon],
    additionalInfo: (
      <Grid container sx={{ overflowX: 'auto' }}>
        <Grid item xs="auto">
          <img src={financialityPreview} width={600} alt=""></img>
          <Box maxWidth={600}>
            <Typography variant="body2">
              Shows sample plotted data queried from the backend api, connected to a PostgreSQL database.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    ),
  },
]

export const PROJECTS = projects.map((project, idx) => {
  project.id = idx + 1
  return project
})
