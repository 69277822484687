import { createTheme, CssBaseline, Grid, Stack, ThemeProvider } from '@mui/material'
import { Box } from '@mui/system'
import { useEffect /*, useState*/ } from 'react'
import './App.css'
import { MOBILE_MAX_WIDTH } from './Constants/Values'
import { useStateContext } from './ContextProvider'
import Home from './Pages/Home'

function App() {
  const { darkMode, isMobile, setIsMobile } = useStateContext()
  //const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    function handleWindowSizeChange() {
      setIsMobile(window.innerWidth <= MOBILE_MAX_WIDTH)
      //setWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [setIsMobile])

  //https://m2.material.io/resources/color/#!/?view.left=1&view.right=0&primary.color=81D4FA
  //light blue 200, blue grey 100
  const lightTheme = createTheme({
    palette: {
      background: {},
      primary: {
        contrastText: '#000',
        dark: '#4ba3c7',
        light: '#b6ffff',
        main: '#81d4fa',
      },
      secondary: {
        contrastText: '#000',
        dark: '#9ea7aa',
        light: '#ffffff',
        main: '#cfd8dc',
      },
      link: '#0000EE',
    },
  })

  //
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {},
      primary: {
        contrastText: '#fff',
        dark: '#1b1b1b',
        light: '#6d6d6d',
        main: '#424242',
      },
      secondary: {
        contrastText: '#fff',
        dark: '#38006b',
        light: '#9c4dcc',
        main: '#6a1b9a',
      },
      link: '#9c4dcc',
    },
  })

  var currTheme = darkMode ? darkTheme : lightTheme
  //Common options for theme
  currTheme.typography.button.textTransform = 'none'
  currTheme.typography.body1.fontSize = '1.15rem'
  currTheme.typography.body2.fontSize = '1rem'

  return (
    <ThemeProvider theme={currTheme}>
      <CssBaseline />
      {isMobile ? (
        <Stack direction="row">
          <Box minWidth="8px" />
          <Home width={window.innerWidth - 16} />
          <Box minWidth="8px" />
        </Stack>
      ) : (
        <div>
          <Grid container wrap="nowrap">
            <Grid item xs={1} backgroundColor="primary.dark"></Grid>
            <Grid item xs={0.25} backgroundColor="background.default"></Grid>
            <Grid item xs>
              <Home />
            </Grid>
            <Grid item xs={0.25} backgroundColor="background.default"></Grid>
            <Grid item xs={1} backgroundColor="primary.dark"></Grid>
          </Grid>
          <Box height="150px" backgroundColor="primary.dark" />
        </div>
      )}
    </ThemeProvider>
  )
}

export default App
