import { Box } from '@mui/system'
import { createRef } from 'react'
import Navbar from '../Components/Navbar'
import { PAGES } from '../Constants/Pages'
import { PROJECTS } from '../Constants/Projects'
import { NAVBAR_HEIGHT } from '../Constants/Values'
import { useStateContext } from '../ContextProvider'

const Home = (props) => {
  const { darkMode, setDarkMode, isMobile } = useStateContext()
  const pageRefs = PAGES.map((page) => createRef())
  const projectRefs = PROJECTS.map((project) => createRef()) //for mobile
  const executeScroll = (ref) => {
    window.scrollTo({ left: 0, top: ref.current.offsetTop - NAVBAR_HEIGHT, behavior: isMobile ? 'auto' : 'smooth' })
  }

  return (
    <div>
      <div>
        <Navbar clicked={() => setDarkMode(!darkMode)} pageSelect={(idx) => executeScroll(pageRefs[idx])} />
      </div>
      <Box marginTop={`${NAVBAR_HEIGHT + 20}px`} />
      <Box width={props.width ? `${props.width}px` : '100%'}>
        {PAGES.map((page, idx) => {
          const Component = page.component
          return (
            <div key={page.name} ref={pageRefs[idx]}>
              <Component
                onProjectClick={(idx) => executeScroll(isMobile ? projectRefs[idx] : pageRefs[1])}
                projectRefs={projectRefs}
              />
            </div>
          )
        })}
      </Box>
      <Box height="100px" />
    </div>
  )
}

export default Home
