import { Divider, Typography, useTheme } from '@mui/material'
import React from 'react'

const More = () => {
  const theme = useTheme()
  return (
    <div>
      <Divider sx={{ mb: 2 }} />
      <Typography variant="h4">More</Typography>
      <Divider />
      <Typography variant="body">
        In addition to software engineering, I also have a passion for finance and helping friends and family plan for
        the future. Even basic finance information can get complicated pretty rapidly. I figured the best way to get the
        basic information across was to make a blog that anyone can reference whenever they have a question. Check out
        my blog here at{' '}
        <a href="https://raygunblog.com" target="_blank" rel="noreferrer" style={{ color: theme.palette.link }}>
          raygunblog.com
        </a>
      </Typography>
    </div>
  )
}

export default More
